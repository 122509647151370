import React, {useState, Button, View, Text} from "react";
// import {Button, View, Text} from 'react-native'
import Main from "./components/main";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
// import CookieBot from 'react-cookiebot/lib/CookieBot';
// const domainGroupId = '76675a3a-4ecd-408b-b259-7da250b42976'

function App() {
  // const [hasCookieBot, setHasCookieBot] = useState(undefined);
  return (

    <div className="Container">
      <header className="App-header"></header>
      <Main />
    </div>
  );
}

export default App;
